import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
  updateVendorsFunc, 
  resetVendorsComponentFunc,
  resetVendorsFunc 
} from '../reducers/vendorsSlice';
// import { changeInputFunc } from '../reducers/myAccountSlice';
import { toastOptions } from '../toastify';
import { handleAPIData } from '../hooks/useCustomApi';
import Input from './Input';
import Button from './Button';

const Register = forwardRef((props, ref) => {
  const { id, onLoginSuccessCallback } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const childRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const { Login } = useSelector(state => state.vendors);
  const [loading, setLoading] = useState(false);


  const handleSignUpClick = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (!Login.enterName && !Login.enterEmail && !Login.enterPhone && !Login.enterAddress && !Login.enterPassword && !Login.reeenterPassword) {
      toast.warning('Please enter something to update', toastOptions);
      return;
    }

    if (!Login.enterName && !Login.enterEmail && !Login.enterPhone && !Login.enterPassword && !Login.reeenterPassword) {
      toast.warning('Please enter mandatory fields', toastOptions);
      return;
    }

    if (!Login.enterName) {
      toast.warning('Please enter name', toastOptions);
      return;
    }

    if (!Login.enterEmail) {
      toast.warning('Please enter email', toastOptions);
      return;
    }

    if (!Login.enterPhone) {
      toast.warning('Please enter phone number', toastOptions);
      return;
    }

    if (!Login.enterPassword) {
      toast.warning('Please enter password', toastOptions);
      return;
    }

    if (!Login.reeenterPassword) {
      toast.warning('Please re-enter password', toastOptions);
      return;
    }

    if (Login.enterPassword !== Login.reeenterPassword) {
      toast.warning('Passwords did not matched', toastOptions);
      return;
    }

    if (loading) {
      return;
    }

    if (Login.enterEmail && !emailRegex.test(Login.enterEmail)) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    }

    if (Login.enterPhone && !phoneRegex.test(Login.enterPhone)) {
      toast.warning('Please enter a valid phone number', toastOptions);
      return;
    }

    const payload = {};

    if (Login.enterName && Login.enterEmail && Login.enterPhone && Login.enterPassword && Login.reeenterPassword) {
      payload.username = Login.enterName.trim();
      payload.email = Login.enterEmail.trim();
      payload.phoneNumber = Login.enterPhone.trim();
      payload.address = Login.enterAddress ? Login.enterAddress.trim() : '';
      payload.password = Login.enterPassword.trim();
    }

    setLoading(true);
    dispatch(resetVendorsFunc());
    let response = await handleAPIData('POST', '/api/register', payload);
    console.log('QQQQQQQQQQQQQQQQQQQQQQQ', response);
    if (response.status === 'success' && response.data.isDups && response.data.message) {
      toast.error(response.data.message, toastOptions);
      handleCancelClick();
    } else if (response.status === 'success' && response?.data?.userCreated && response?.data?.message) {
      toast.success(response.data.message, toastOptions);
      handleCancelClick();
      onLoginSuccessCallback(response.data.email);
      localStorage.setItem('access_token', response.data.token);
    } else if (response.status === 'error' && response.data?.message) {
      toast.error(response.data.message, toastOptions);
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const handleCancelClick = () => {
    for (let index = 0; index < childRefs.length; index++) {
      if (childRefs[index].current) {
        childRefs[index].current.resetRefCalled('Login');
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleCancelClick
  }));

  return (
    <div className="tab-pane fade" id="register-pane" role="tabpanel" tabIndex="0">
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[0]} id={"register-enter-name"} keyName={"enterName"} componentName={"Login"} placeholder={"Enter name *"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[1]} id={"register-enter-email"} keyName={"enterEmail"} componentName={"Login"} placeholder={"Enter email *"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[2]} id={"register-enter-phone"} keyName={"enterPhone"} componentName={"Login"} placeholder={"Enter phone *"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[3]} id={"register-enter-address"} keyName={"enterAddress"} componentName={"Login"} placeholder={"Enter address"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[4]} id={"register-enter-password"} keyName={"enterPassword"} componentName={"Login"} placeholder={"Enter password *"} type={"password"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[5]} id={"register-reenter-password"} keyName={"reeenterPassword"} componentName={"Login"} placeholder={"Re-enter password *"} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Button id={"register-sign-up-btn"} loading={loading} handleBtnClick={handleSignUpClick} btnType={"primary"} classes={"primary-pd float-end"} label={"Sign up"} />
          <Button id={"register-cancel-btn"} handleBtnClick={handleCancelClick} btnType={"secondary"} classes={"primary-pd float-end mx-4"} label={"Cancel"} />
        </div>
      </div>
    </div>
  )
});

export default Register;
