import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
  updateVendorsFunc, 
  resetVendorsComponentFunc,
  resetVendorsFunc 
} from '../reducers/vendorsSlice';
// import { resetHomeFunc } from '../reducers/homeSlice';
// import { changeInputFunc, resetInputFunc } from '../reducers/myAccountSlice';
import { toastOptions } from '../toastify';
import { handleAPIData } from '../hooks/useCustomApi';
import Input from './Input';
import Button from './Button';

const Login = forwardRef((props, ref) => {
  const { id, onLoginSuccessCallback } = props;
  const dispatch = useDispatch();
  const childRefs = [useRef(), useRef()];
  const { Login } = useSelector(state => state.vendors);
  const [loading, setLoading] = useState(false);


  const handleSignInClick = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!Login.enterEmailLogin && !Login.enterPasswordLogin) {
      toast.warning('Please enter something to update', toastOptions);
      return;
    }

    if (!Login.enterEmailLogin) {
      toast.warning('Please enter email', toastOptions);
      return;
    }

    if (!Login.enterPasswordLogin) {
      toast.warning('Please enter password', toastOptions);
      return;
    }

    if (loading) {
      return;
    }

    if (Login.enterEmailLogin && !emailRegex.test(Login.enterEmailLogin)) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    }

    console.log('sign in changes', Login.enterEmailLogin, Login.enterPasswordLogin);

    const payload = {};

    if (Login.enterEmailLogin && Login.enterPasswordLogin) {
      payload.email = Login.enterEmailLogin.trim();
      payload.password = Login.enterPasswordLogin.trim();
    }

    setLoading(true);
    dispatch(resetVendorsFunc());
    let response = await handleAPIData('POST', '/api/login', payload);
    console.log('QQQQQQQQQQQQQQQQQQQQQQQ', response);
    if (response.status === 'success' && response.data.invalidPassword && response.data.message) {
      toast.error(response.data.message, toastOptions);
    } else if (response.status === 'success' && response.data.invalidUser && response.data.message) {
      handleCancelClick();
      toast.error(response.data.message, toastOptions);
    } else if (response.status === 'success' && response.data.userLoggedIn && response.data.message) {
      toast.success(response.data.message, toastOptions);
      handleCancelClick();
      onLoginSuccessCallback(response.data.email);
      localStorage.setItem('access_token', response.data.token);
    } else if (response.status === 'error' && response.data.message) {
      toast.error(response.data.message, toastOptions);
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const handleCancelClick = () => {
    for (let index = 0; index < childRefs.length; index++) {
      if (childRefs[index].current) {
        childRefs[index].current.resetRefCalled('Login');
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleCancelClick
  }));

  return (
    <div className="tab-pane fade show active" id={id} role="tabpanel" aria-labelledby="tab-1"
      tabIndex="0">
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[0]} id={"login-enter-email"} keyName={"enterEmailLogin"} componentName={"Login"} placeholder={"Enter email"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Input ref={childRefs[1]} id={"register-enter-password"} keyName={"enterPasswordLogin"} componentName={"Login"} placeholder={"Enter password"} type={"password"} />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 mb-3">
          <a href="#!" className="forgot-link">Forgot password?</a>
        </div>
      </div> */}
      <div className="row">
        <div className="col">
          {/* <GoogleLogin
                          onSuccess={credentialResponse => {
                            console.log('credentialResponse', credentialResponse);
                            const cred = decodeJwt(credentialResponse.credential);
                            console.log('Login Success: currentUser: data', cred);
                          }}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                        /> */}
          <Button id={"login-sign-in-btn"} loading={loading} handleBtnClick={handleSignInClick} btnType={"primary"} classes={"primary-pd float-end"} label={"Sign In"} />
          <Button id={"login-cancel-btn"} handleBtnClick={handleCancelClick} btnType={"secondary"} classes={"primary-pd float-end mx-4"} label={"Cancel"} />

        </div>
      </div>
    </div>
  )
});

export default Login;
