import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from '../toastify';
import { handleAPIData } from '../hooks/useCustomApi';
import Register from '../components/Register';
import Login from '../components/Login';

const LoginRegister = forwardRef((props, ref) => {
  const { id, onLoginSuccess } = props;

  const onLoginSuccessCallback = (value) => {
    onLoginSuccess(value);
  };
  return (
    <>
      <div className="header header-login">
        <div className="header-left">
          <a href="#!" className="logo">
            <img src="./assets/images/logo.png" alt="logo" />
          </a>
        </div>
      </div>
      <div className="container-fluid ps-0">
        <div className="row align-items-flex-start">
          <div className="col-md-7 col-sm-12">
            <div className="login-image">
            </div>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-12 mt-5">
            <div className="container-xxl section-block-inner">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 text-center">
                  <h1 className="mb-2">Welcome to Hajjrah</h1>
                  <p className="hero-text">Please Sign In / Sign Up using your Email/Mobile to continue</p>
                </div>
              </div>
            </div>
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="header-login-btn" data-bs-toggle="tab" data-bs-target="#login-pane" type="button"
                      role="tab">Sign In</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="header-register-btn" data-bs-toggle="tab" data-bs-target="#register-pane" type="button"
                      role="tab">Sign Up</button>
                  </li>
                </ul>
                <div className="row tab-margin">
                  <div className="col">
                    <div className="tab-content" id="login-register-content">
                      <Login id={"login-pane"} onLoginSuccessCallback={onLoginSuccessCallback} />                   
                      <Register id={"register-pane"} onLoginSuccessCallback={onLoginSuccessCallback} /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
});

export default LoginRegister;
