import React from 'react';

// Dynamically import all components in the components directory
const components = { 
  Dashboard: React.lazy(() => import('../pages/Dashboard')),
  PackageManagementNew: React.lazy(() => import('../pages/PackageManagementNew')),
  PilgrimageBookingNew: React.lazy(() => import('../pages/PilgrimageBookingNew')),
  PilgrimageBookingList: React.lazy(() => import('../pages/PilgrimageBookingList')),
  PackageManagementList: React.lazy(() => import('../pages/PackageManagementList')),
  PilgrimageBookingView: React.lazy(() => import('../pages/PilgrimageBookingView')),
  PackageManagementView: React.lazy(() => import('../pages/PackageManagementView')),
  HotelManagementList: React.lazy(() => import('../pages/HotelManagementList')),
  HotelManagementNew: React.lazy(() => import('../pages/HotelManagementNew')),
  HotelManagementView: React.lazy(() => import('../pages/HotelManagementView')),
  CabPromotionList: React.lazy(() => import('../pages/CabPromotionList')),
  CabPromotionNew: React.lazy(() => import('../pages/CabPromotionNew')),
  FleetManagementList: React.lazy(() => import('../pages/FleetManagementList')),
  FleetManagementNew: React.lazy(() => import('../pages/FleetManagementNew')),
  FleetManagementView: React.lazy(() => import('../pages/FleetManagementView')),
  DriverManagementList: React.lazy(() => import('../pages/DriverManagementList')),
  DriverManagementNew: React.lazy(() => import('../pages/DriverManagementNew')),
  DriverManagementView: React.lazy(() => import('../pages/DriverManagementView')),
  CabBookingList: React.lazy(() => import('../pages/CabBookingList')),
  RestaurantMenuList: React.lazy(() => import('../pages/RestaurantMenuList')),
  RestaurantMenuNew: React.lazy(() => import('../pages/RestaurantMenuNew')),
  RestaurantPromotionList: React.lazy(() => import('../pages/RestaurantPromotionList')),
  RestaurantPromotionNew: React.lazy(() => import('../pages/RestaurantPromotionNew')),
  RestaurantPaymentList: React.lazy(() => import('../pages/RestaurantPaymentList')),
};

const PageRenderer = ({ componentName, data }) => {
  console.log('#@#@#@#@#@#', componentName, data);
  const Component = components[componentName];

  if (!Component) return <div>Component not found</div>;

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component obj={data} />
    </React.Suspense>
  );
};

export default PageRenderer;
