import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PageRenderer from './components/PageRenderer';
import { vData } from './constant/vendor';
import {
  resetVendorsComponentFunc, resetVendorsFunc, updateVendorsFunc
} from './reducers/vendorsSlice';

import 'react-toastify/dist/ReactToastify.css';
import Vendors from './Vendors';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import RequireAuth from './components/RequireAuth';


const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogoClick = () => {
    history.push('/dashboard');
  };
  
  return (
    <>
      <RequireAuth>
        <Switch>
          <>
            <Route
              path="/dashboard"
              render={() => <PageRenderer componentName={'Dashboard'} data={vData[0]} />
              }
            />
            <Route
              path="/package-management-list"
              render={() => <PageRenderer componentName={'PackageManagementList'} data={vData[1].subMenu[0]} />
              }
            />
            <Route
              path="/package-management-new"
              render={() => <PageRenderer componentName={'PackageManagementNew'} data={vData[1].subMenu[1]} />
              }
            />
            <Route
              path="/package-management-view"
              render={() => <PageRenderer componentName={'PackageManagementView'} data={vData[1].subMenu[2]} />
              }
            />
            <Route
              path="/pilgrimage-booking-new"
              render={() => <PageRenderer componentName={'PilgrimageBookingNew'} data={vData[1].subMenu[3]} />
              }
            />
            <Route
              path="/pilgrimage-booking-list"
              render={() => <PageRenderer componentName={'PilgrimageBookingList'} data={vData[1].subMenu[4]} />
              }
            />
            <Route
              path="/pilgrimage-booking-view"
              render={() => <PageRenderer componentName={'PilgrimageBookingView'} data={vData[1].subMenu[5]} />
              }
            />
            <Route
              path="/hotel-management-list"
              render={() => <PageRenderer componentName={'HotelManagementList'} data={vData[2].subMenu[0]} />
              }
            />
            <Route
              path="/hotel-management-new"
              render={() => <PageRenderer componentName={'HotelManagementNew'} data={vData[2].subMenu[1]} />
              }
            />
            <Route
              path="/hotel-management-view"
              render={() => <PageRenderer componentName={'HotelManagementView'} data={vData[1].subMenu[3]} />
              }
            />
            <Route
              path="/cab-promotion-list"
              render={() => <PageRenderer componentName={'CabPromotionList'} data={vData[3].subMenu[0]} />
              }
            />
            <Route
              path="/cab-promotion-new"
              render={() => <PageRenderer componentName={'CabPromotionNew'} data={vData[3].subMenu[1]} />
              }
            />
            <Route
              path="/fleet-management-list"
              render={() => <PageRenderer componentName={'FleetManagementList'} data={vData[3].subMenu[2]} />
              }
            />
            <Route
              path="/fleet-management-new"
              render={() => <PageRenderer componentName={'FleetManagementNew'} data={vData[3].subMenu[3]} />
              }
            />
            <Route
              path="/fleet-management-view"
              render={() => <PageRenderer componentName={'FleetManagementView'} data={vData[3].subMenu[4]} />
              }
            />
            <Route
              path="/driver-management-list"
              render={() => <PageRenderer componentName={'DriverManagementList'} data={vData[3].subMenu[5]} />
              }
            />
            <Route
              path="/driver-management-new"
              render={() => <PageRenderer componentName={'DriverManagementNew'} data={vData[3].subMenu[6]} />
              }
            />
            <Route
              path="/driver-management-view"
              render={() => <PageRenderer componentName={'DriverManagementView'} data={vData[3].subMenu[7]} />
              }
            />
            <Route
              path="/cab-booking-list"
              render={() => <PageRenderer componentName={'CabBookingList'} data={vData[3].subMenu[8]} />
              }
            />
            <Route
              path="/restaurant-menu-list"
              render={() => <PageRenderer componentName={'RestaurantMenuList'} data={vData[4].subMenu[0]} />
              }
            />
            <Route
              path="/restaurant-menu-new"
              render={() => <PageRenderer componentName={'RestaurantMenuNew'} data={vData[4].subMenu[1]} />
              }
            />
            <Route
              path="/restaurant-promotion-list"
              render={() => <PageRenderer componentName={'RestaurantPromotionList'} data={vData[4].subMenu[2]} />
              }
            />
            <Route
              path="/restaurant-promotion-new"
              render={() => <PageRenderer componentName={'RestaurantPromotionNew'} data={vData[4].subMenu[3]} />
              }
            />
            <Route
              path="/restaurant-payment-list"
              render={() => <PageRenderer componentName={'RestaurantPaymentList'} data={vData[4].subMenu[4]} />
              }
            />
          </>
        </Switch>
      </RequireAuth>
      <ToastContainer />
    </>
  )
};

export default App;
