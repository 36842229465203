const dateStyles = {
  border: '1px solid #79747E',
  height: '47px',
  fontSize: '16px',
  lineHeight: '50px',
  borderRadius: '6px'
};

const facilitiesData = [
  { category: "Haram Access and Services", name: "Wheelchair rentals", firstName: "Wheelchair rentals" },
  { category: "Haram Access and Services", name: "Zamzam water distribution", firstName: "Zamzam water distribution" },
  { category: "Haram Access and Services", name: "Prayer mats and seating areas", firstName: "Prayer mats and seating areas" },
  { category: "Haram Access and Services", name: "Lost and found services", firstName: "Lost and found services" },
  { category: "Transportation Facilities", name: "Free shuttle services to Haram", firstName: "Free shuttle services to Haram" },
  { category: "Transportation Facilities", name: "Public transport networks (buses, metro)", firstName: "Public transport networks (buses, metro)" },
  { category: "Transportation Facilities", name: "Ride-hailing services (Uber, Careem)", firstName: "Ride-hailing services (Uber, Careem)" },
  { category: "Accommodation Services", name: "Hotels with proximity to Haram", firstName: "Hotels with proximity to Haram" },
  { category: "Accommodation Services", name: "In-house dining and prayer areas", firstName: "In-house dining and prayer areas" },
  { category: "Food and Beverage", name: "Halal restaurants and food courts", firstName: "Halal restaurants and food courts" },
  { category: "Food and Beverage", name: "Water and snack distribution at key points", firstName: "Water and snack distribution at key points" },
  { category: "Health and Medical Support", name: "Clinics and hospitals nearby", firstName: "Clinics and hospitals nearby" },
  { category: "Health and Medical Support", name: "Emergency medical response services", firstName: "Emergency medical response services" },
  { category: "Visa and Immigration Assistance", name: "E-visa processing", firstName: "E-visa processing" },
  { category: "Visa and Immigration Assistance", name: "Pilgrim service centers at airports", firstName: "Pilgrim service centers at airports" },
  { category: "Guidance and Information", name: "Multilingual signboards and apps", firstName: "Multilingual signboards and apps" },
  { category: "Guidance and Information", name: "Volunteer support for directions", firstName: "Volunteer support for directions" },
  { category: "Security and Safety", name: "24/7 surveillance in Haram areas", firstName: "24/7 surveillance in Haram areas" },
  { category: "Security and Safety", name: "Lost child/person assistance", firstName: "Lost child/person assistance" },
  { category: "Religious Guidance", name: "Scholars and guides available for queries", firstName: "Scholars and guides available for queries" },
  { category: "Religious Guidance", name: "Free distribution of Quran and Islamic literature", firstName: "Free distribution of Quran and Islamic literature" },
  { category: "Financial and Banking Services", name: "ATMs and currency exchange counters", firstName: "ATMs and currency exchange counters" },
  { category: "Financial and Banking Services", name: "Pilgrim-friendly banking facilities", firstName: "Pilgrim-friendly banking facilities" },
  { category: "Shopping and Souvenirs", name: "Malls and marketplaces nearby", firstName: "Malls and marketplaces nearby" },
  { category: "Shopping and Souvenirs", name: "Availability of religious items", firstName: "Availability of religious items" },
  { category: "Luggage and Storage Services", name: "Cloakrooms and lockers at hotels and Haram entrances", firstName: "Cloakrooms and lockers at hotels and Haram entrances" },
  { category: "Special Facilities for the Elderly and Disabled", name: "Electric scooters and wheelchairs", firstName: "Electric scooters and wheelchairs" },
  { category: "Special Facilities for the Elderly and Disabled", name: "Dedicated prayer areas and ramps", firstName: "Dedicated prayer areas and ramps" },
  { category: "Communication Facilities", name: "Wi-Fi hotspots", firstName: "Wi-Fi hotspots" },
  { category: "Communication Facilities", name: "Mobile SIM cards and recharge services", firstName: "Mobile SIM cards and recharge services" },
  { category: "Group and Tour Operator Services", name: "Organized Ziyarah tours", firstName: "Organized Ziyarah tours" },
  { category: "Group and Tour Operator Services", name: "Liaison for travel and accommodation logistics", firstName: "Liaison for travel and accommodation logistics" }
];

export const vData = [ 
  {
    id: "dashboard-id",
    title: "Dashboard",
    link: '/dashboard',
    subMenu: [],
    component: "Dashboard"
  },
  {
    id: "hajj-umrah-providers-id",
    title: "Hajj & Umrah Providers",
    link: "",
    exact: true,
    subMenu: [
      {
        id: "package-management-list-id",
        title: "Package Management",
        link: '/package-management-list',
        component: "PackageManagementList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "package-management-list-main-heading",
                label: "Package Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "package-management-list-new-package-btn",
                    label: "New Package",
                    keyName: "packageManagementListNewPackageBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "package-management-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "package-management-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "package-management-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "packageManagementListOpenBtn",
                  edit: "packageManagementListEditBtn",
                  delete: "packageManagementListDeleteBtn"
                },
                value: {
                  columns: 8,
                  thead: [
                    "Package Name",
                    "Itinerary",
                    "Price",
                    "Group Size",
                    "Documents Required",
                    "Accommodation",
                    "Transportation",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "package-management-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "package-management-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "package-management-new-id",
        title: "Package Management New",
        link: "/package-management-new",
        component: "PackageManagementNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "package-management-new-main-heading",
                label: "Add Package Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "package-management-new-back-btn",
                    label: "Back",
                    keyName: "packageManagementNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "package-management-new-create-btn",
                    label: "Create",
                    keyName: "packageManagementNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "package-management-new-package-name",
                keyName: "packageName",
                label: "Package Name",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "package-management-new-traveler-name-for",
                placeholder: "",
                class: [["col-4"]]
              },             
              {
                id: "package-management-new-departure",
                keyName: "departure",
                label: "Departure",
                type: "multiSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: ['Delhi', 'Chennai', 'Mumbai', 'Bengaluru'].map(
                  item => ({ label: item, value: item })
                ),
                value: [],
                htmlFor: "package-management-new-departure-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-destination",
                keyName: "destination",
                label: "Destination",
                type: "singleSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: ['London', 'Abu Dhabi', 'Dubai', 'Sydney', 'New York'].map(
                  item => ({ label: item, value: item })
                ),
                value: '',
                htmlFor: "package-management-new-destination-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-price",
                keyName: "packageManagementNewPrice",
                label: "Price",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "package-management-new-price-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-group-size",
                keyName: "packageManagementNewGroupSize",
                label: "Group Size",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "package-management-new-group-size-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-days",
                keyName: "days",
                label: "Days",
                type: "singleSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(
                  item => ({ label: item, value: item })
                ),
                value: '',
                htmlFor: "package-management-new-days-for",
                placeholder: "",
                class: [["col-2"]]
              },
              {
                id: "package-management-new-nights",
                keyName: "nights",
                label: "Nights",
                type: "singleSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(
                  item => ({ label: item, value: item })
                ),
                value: '',
                htmlFor: "package-management-new-nights-for",
                placeholder: "",
                class: [["col-2"]]
              },
              {
                id: "package-management-new-documents-required",
                label: "Documents Required",
                type: "checkbox",
                name: "packageManagementDocumentsRequired",
                description: "checkbox with label text",
                htmlType: 6,
                class: [["col-4"]],
                fields: [
                  {
                    id: "package-management-new-visa",
                    label: "Visa",
                    value: false,
                    keyName: "packageManagementVisa",
                    htmlFor: "package-management-new-visa-for",
                  },
                  {
                    id: "package-management-new-medical",
                    label: "Medical",
                    value: false,
                    keyName: "packageManagementMedical",
                    htmlFor: "package-management-new-medical-for",
                  },
                ]
              },
              
              {
                id: "package-management-new-transportation-required",
                keyName: "packMangTransportation",
                label: "Transportation Required",
                type: "multiSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: [
                  'Haram Shuttle Services', 
                  'Public Buses (SAPTCO)', 
                  'Taxi Services', 
                  'Ride-Hailing Apps (Careem, Uber)', 'Car Rentals', 'Metro (Makkah Metro)', 'Hotel Shuttle Services', 'Private Chauffeur Services', 'Walking', 'Airport Transfers'].map(
                  item => ({ label: item, value: item })
                ),
                value: '',
                htmlFor: "package-management-new-transportation-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-facilities",
                keyName: "facilities",
                label: "Facilities",
                type: "groupSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: facilitiesData,
                value: [],
                htmlFor: "package-management-new-facilities-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-hajj-date",
                keyName: "packageManagementNewHajjDates",
                label: "Availability for Hajj",
                type: "daterangepicker",
                description: "datepicker rsuite",
                htmlType: 4,
                oneTap: false,
                size: "lg",
                value: null,
                dateStyles,
                htmlFor: "package-management-new-hajj-date-for",
                placeholder: "Select Date",
                format: "dd-MM-yyyy",
                class: [["col-4"]]
              },
              {
                id: "package-management-new-umrah-date",
                keyName: "packageManagementNewUmrahDates",
                label: "Availability for Umrah",
                type: "daterangepicker",
                description: "datepicker rsuite",
                htmlType: 4,
                oneTap: false,
                size: "lg",
                value: null,
                dateStyles,
                htmlFor: "package-management-new-umrah-date-for",
                placeholder: "Select Date",
                format: "dd-MM-yyyy",
                class: [["col-4"]]
              },  
              {
                id: "package-management-new-upload-images",
                label: "Upload Images",
                type: "file",
                multiple: false,
                keyName: "packMangUploadImages",
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "package-management-new-upload-images-for",
                class: [["col-4"]]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "package-management-new-itinerary",
                htmlType: 'group_1',
                class: [["col-6"]],
                fields: [
                  {
                    id: "package-management-new-itinerary-title-1",
                    keyName: "packageManagementNewItineraryTitle_1",
                    label: "Itinerary 1",
                    type: "text",
                    description: "input with label text",
                    value: "",
                    htmlFor: "package-management-new-itinerary-title-1-for",
                    placeholder: "Title",
                    class: [["mb-2"]]
                  },
                  {
                    id: "package-management-new-itinerary-description-1",
                    keyName: "packageManagementNewItineraryDescription_1",
                    label: "",
                    type: "textarea",
                    description: "textarea without label text",
                    value: "",
                    htmlFor: "package-management-new-itinerary-description-1-for",
                    placeholder: "Description",
                    class: [["mb-2"]]
                  },
                  {
                    type: "button",
                    actualType: "a",
                    description: "a button",
                    entity: [
                      {
                        id: "package-management-new-add-new-package-btn",
                        label: "Add New Package",
                        keyName: "packageManagementNewAddNewPackageBtn",
                        icon: "bi-plus-circle-fill",
                        class: [["secondary"]]
                      },
                      {
                        id: "package-management-new-delete-btn",
                        label: "Delete",
                        keyName: "packageManagementNewDeleteBtn",
                        icon: "bi-trash",
                        class: [["secondary"]]
                      }
                    ]
                  }
                ]
              },
              {
                id: "package-management-new-transportation-guidelines-main",
                htmlType: 'group_1',
                class: [["col-6"]],
                fields: [ 
                  {
                    id: "package-management-new-transportation-guidelines",
                    label: "Transportation Guidelines",
                    description: "label text",
                    htmlFor: "package-management-new-transportation-guidelines-for",
                    class: [["mb-3", "col-12"]]
                  },                     
                  {
                    id: "package-management-new-inclusion",
                    label: "Inclusion",
                    type: "checkbox",
                    name: "packageManagementNewInclusion",
                    description: "checkbox with label text",
                    class: [["mb-3", "col-12"]],
                    fields: [
                      {
                        id: "package-management-new-hajj-visa",
                        label: "Hajj Visa",
                        value: false,
                        keyName: "packageManagementHajjVisa",
                        htmlFor: "package-management-new-hajj-visa-for",
                      },
                      {
                        id: "package-management-new-ticket",
                        label: "Ticket",
                        value: false,
                        keyName: "packageManagementTicket",
                        htmlFor: "package-management-new-ticket-for",
                      },
                      {
                        id: "package-management-new-hajj-kit",
                        label: "Hajj Kit",
                        value: false,
                        keyName: "packageManagementHajjKit",
                        htmlFor: "package-management-new-hajj-kit-for",
                      },
                      {
                        id: "package-management-new-stay",
                        label: "Stay",
                        value: false,
                        keyName: "packageManagementStay",
                        htmlFor: "package-management-new-stay-for",
                      },
                      {
                        id: "package-management-new-transportation",
                        label: "Transportation",
                        value: false,
                        keyName: "packageManagementTransportation",
                        htmlFor: "package-management-new-transportation-for",
                      },
                      {
                        id: "package-management-new-buffet",
                        label: "3 Times Buffet Indian Food",
                        value: false,
                        keyName: "packageManagementBuffet",
                        htmlFor: "package-management-new-buffet-for",
                      },
                      {
                        id: "package-management-new-ziyarat",
                        label: "Ziyarat of Makkahh and Madina",
                        value: false,
                        keyName: "packageManagementZiyarat",
                        htmlFor: "package-management-new-ziyarat-for",
                      },
                      {
                        id: "package-management-new-laundry",
                        label: "Laundry",
                        value: false,
                        keyName: "packageManagementLaundry",
                        htmlFor: "package-management-new-laundry-for",
                      },
                      {
                        id: "package-management-new-zamzam",
                        label: "Zam Zam 5 litres",
                        value: false,
                        keyName: "packageManagementZamZam",
                        htmlFor: "package-management-new-zamzam-for",
                      },
                    ]
                  },      
                  {
                    id: "package-management-new-exclusion",
                    label: "Exclusion",
                    type: "checkbox",
                    name: "packageManagementNewExclusion",
                    description: "checkbox with label text",
                    class: [["mt-3", "mb-3", "col-12"]],
                    fields: [
                      {
                        id: "package-management-new-excess-bagggage",
                        label: "Excess Bagggage",
                        value: false,
                        keyName: "packageManagementExcessBagggage",
                        htmlFor: "package-management-new-excess-bagggage-for",
                      },
                      {
                        id: "package-management-new-tawaf-e-ziyarat",
                        label: "Tawaf-e-Ziyarat",
                        value: false,
                        keyName: "packageManagementTawafeZiyarat",
                        htmlFor: "package-management-new-tawaf-e-ziyarat-for",
                      },
                      {
                        id: "package-management-new-room-services",
                        label: "Room Services",
                        value: false,
                        keyName: "packageManagementRoomServices",
                        htmlFor: "package-management-new-room-services-for",
                      },
                      {
                        id: "package-management-new-porter-services",
                        label: "Porter Services",
                        value: false,
                        keyName: "packageManagementPorterServices",
                        htmlFor: "package-management-new-porter-services-for",
                      },
                      {
                        id: "package-management-new-individual-transfer",
                        label: "Individual Transfer",
                        value: false,
                        keyName: "packageManagementIndividualTransfer",
                        htmlFor: "package-management-new-individual-transfer-for",
                      },
                      {
                        id: "package-management-new-qurbani",
                        label: "Qurbani",
                        value: false,
                        keyName: "packageManagementQurbani",
                        htmlFor: "package-management-new-qurbani-for",
                      },
                      {
                        id: "package-management-new-gst-tcs",
                        label: "GST & TCS",
                        value: false,
                        keyName: "packageManagementGSTTCS",
                        htmlFor: "package-management-new-gst-tcs-for",
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: "package-management-view-id",
        title: "Package Management View",
        link: '/package-management-view',
        component: "PackageManagementView",
        hide: "hider",
        looper: (num, name, gender, mobile, email, yesFiles, noFiles) => {
          return {
            class: [""],
            fields: [
              {
                id: `pilgrimage-booking-view-heading-traveler-${num}`,
                label: `Traveler ${num}`,
                type: "h4",
                description: "h4 text",
                htmlType: 1,
                class: [["col-12"], ["mt-3 mb-3"]]
              },
              {
                id: `pilgrimage-booking-view-traveler-name-${num}`,
                label: "Traveler Name",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: name || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-gender-${num}`,
                label: "Gender",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: gender || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-mobile-number-${num}`,
                label: "Mobile Number",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: mobile || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-email-id-${num}`,
                label: "Email Id",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: email || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-documents-uploaded-${num}`,
                label: "Documents Uploaded",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: yesFiles || "",
                value2: noFiles || "",
                class: [["col-6"], ["text-danger"]]
              },
            ]
          }
        },
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "pilgrimage-booking-view-main-heading",
                label: "View Pilgrimage Booking",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "pilgrimage-booking-view-back-btn",
                    label: "Back",
                    keyName: "pilgrimageBookingViewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "pilgrimage-booking-view-cancel-booking-btn",
                    label: "Cancel Booking",
                    keyName: "pilgrimageBookingViewCancelBookingBtn",
                    class: [["secondary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-view-package-name",
                label: "Package Name",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: "wer",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-view-from-date",
                label: "From Date",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: "eew",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-view-to-date",
                label: "To Date",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: "ers",
                class: [["col-3"]]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-view-heading-user-details",
                label: "User details",
                type: "h3",
                description: "h3 text",
                htmlType: 1,
                class: [["col-12"], ["mt-3 mb-3"]]
              },
            ]
          },
        ]
      },
      {
        id: "pilgrimage-booking-new-id",
        title: "Pilgrimage Booking New",
        link: "/pilgrimage-booking-new",
        component: "PilgrimageBookingNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "pilgrimage-booking-new-main-heading",
                label: "New Piligrimage Booking",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "pilgrimage-booking-new-back-btn",
                    label: "Back",
                    keyName: "pilgrimageBookingNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "pilgrimage-booking-new-book-btn",
                    label: "Book",
                    keyName: "pilgrimageBookingNewBookBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-new-select-package",
                label: "Select Package",
                type: "select",
                description: "select with label text",
                htmlFor: "pilgrimage-booking-new-select-package-for",
                keyName: "pilgrimageBookingNewPackage",
                htmlType: 3,
                selectedOption: "",
                value: "",
                class: [["col-3"]],
                options: [
                  { value: "", label: "Select" },
                  { value: "package1", label: "Package 1" },
                  { value: "package2", label: "Package 2" },
                  { value: "package3", label: "Package 3" },
                ]
              },
              {
                id: "pilgrimage-booking-new-fromDate",
                keyName: "pilgrimageBookingNewFromDate",
                label: "From Date",
                type: "datepicker",
                description: "datepicker rsuite",
                htmlType: 4,
                oneTap: true,
                htmlFor: "pilgrimage-booking-new-fromDate-for",
                size: "lg",
                value: null,
                dateStyles,
                placeholder: "Select Date",
                format: "dd-MM-yyyy",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-new-toDate",
                keyName: "pilgrimageBookingNewToDate",
                label: "To Date",
                type: "datepicker",
                description: "datepicker rsuite",
                htmlType: 4,
                oneTap: true,
                htmlFor: "pilgrimage-booking-new-toDate-for",
                size: "lg",
                value: null,
                dateStyles,
                placeholder: "Select Date",
                format: "dd-MM-yyyy",
                class: [["col-3"]]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-new-heading-user-details",
                label: "User details",
                type: "h3",
                description: "h3 text",
                htmlType: 1,
                class: [["col-12"], ["mt-3 mb-3"]]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-new-heading-traveler-1",
                label: "Traveler 1",
                type: "h4",
                description: "h4 text",
                htmlType: 1,
                class: [["col-12"], ["mt-3 mb-3"]]
              },
              {
                id: "pilgrimage-booking-new-traveler-name-1",
                keyName: "pilgrimageBookingNewTravelerName_1",
                label: "Traveller name",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "pilgrimage-booking-new-traveler-name-for-1",
                placeholder: "",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-new-gender-1",
                label: "Gender",
                type: "radio",
                name: "pilBookGender_1",
                description: "radio with label text",
                htmlType: 6,
                value: "",
                class: [["col-3"]],
                keyName: "pilgrimageBookingNewGender_1",
                fields: [
                  {
                    id: "pilgrimage-booking-new-gender-male-1",
                    label: "Male",
                    value: "pilBookGenderMale_1",
                    htmlFor: "pilgrimage-booking-new-gender-male-for-1",
                  },
                  {
                    id: "pilgrimage-booking-new-gender-female-1",
                    label: "Female",
                    value: "pilBookGenderFemale_1",
                    htmlFor: "ppilgrimage-booking-new-gender-female-for-1",
                  }
                ]
              },
              {
                id: "pilgrimage-booking-new-mobile-number-1",
                label: "Mobile Number",
                type: "text",
                keyName: "pilgrimageBookingNewMobileNumber_1",
                actualType: "number",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "pilgrimage-booking-new-mobile-number-for-1",
                placeholder: "",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-new-email-id-1",
                label: "Email ID",
                type: "email",
                keyName: "pilgrimageBookingNewEmailId_1",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "pilgrimage-booking-new-email-id-for-1",
                placeholder: "",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-new-upload-visa-1",
                label: "Upload Visa",
                type: "file",
                multiple: false,
                keyName: "pilgrimageBookingNewUploadVisa_1",
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "pilgrimage-booking-new-upload-visa-for-1",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-new-upload-passport-1",
                label: "Upload Passport",
                type: "file",
                multiple: false,
                keyName: "pilgrimageBookingNewUploadPassport_1",
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "pilgrimage-booking-new-upload-passport-for-1",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-new-upload-medical-1",
                label: "Upload Medical",
                type: "file",
                multiple: false,
                keyName: "pilgrimageBookingNewUploadMedical_1",
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "pilgrimage-booking-new-upload-medical-for-1",
                class: [["col-3"]]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 5,
                class: [[""]],
                entity: [
                  {
                    id: "pilgrimage-booking-new-add-new-traveler-btn",
                    label: "Add New Traveler",
                    keyName: "pilgrimageBookingNewAddNewTravelerBtn",
                    icon: "bi-plus-circle-fill",
                    class: [["secondary"]]
                  },
                  {
                    id: "pilgrimage-booking-new-delete-btn",
                    label: "Delete",
                    keyName: "pilgrimageBookingNewDeleteBtn",
                    icon: "bi-trash",
                    class: [["secondary"]]
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        id: "pilgrimage-booking-list-id",
        title: "Pilgrimage Booking",
        link: "/pilgrimage-booking-list",
        component: "PilgrimageBookingList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "pilgrimage-booking-list-main-heading",
                label: "Pilgrimage Booking",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "pilgrimage-booking-list-new-booking-btn",
                    label: "New Booking",
                    keyName: "pilgrimageBookingListNewBookingBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "pilgrimage-booking-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "pilgrimage-booking-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "pilgrimageBookingListOpenBtn",
                  edit: "pilgrimageBookingListEditBtn",
                  delete: "pilgrimageBookingListDeleteBtn"
                },
                value: {
                  columns: 9,
                  thead: [
                    "Package",
                    "Traveler Name",
                    "Travelers Count",
                    "From Date",
                    "To Date",
                    "Email",
                    "Document Status",
                    "Status",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "pilgrimage-booking-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "pilgrimage-booking-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "pilgrimage-booking-view-id",
        title: "Pilgrimage Booking View",
        link: '/pilgrimage-booking-view',
        component: "PilgrimageBookingView",
        hide: "hider",
        looper: (num, name, gender, mobile, email, yesFiles, noFiles) => {
          return {
            class: [""],
            fields: [
              {
                id: `pilgrimage-booking-view-heading-traveler-${num}`,
                label: `Traveler ${num}`,
                type: "h4",
                description: "h4 text",
                htmlType: 1,
                class: [["col-12"], ["mt-3 mb-3"]]
              },
              {
                id: `pilgrimage-booking-view-traveler-name-${num}`,
                label: "Traveler Name",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: name || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-gender-${num}`,
                label: "Gender",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: gender || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-mobile-number-${num}`,
                label: "Mobile Number",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: mobile || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-email-id-${num}`,
                label: "Email Id",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: email || "",
                class: [["col-3"]]
              },
              {
                id: `pilgrimage-booking-view-documents-uploaded-${num}`,
                label: "Documents Uploaded",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: yesFiles || "",
                value2: noFiles || "",
                class: [["col-6"], ["text-danger"]]
              },
            ]
          }
        },
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "pilgrimage-booking-view-main-heading",
                label: "View Pilgrimage Booking",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "pilgrimage-booking-view-back-btn",
                    label: "Back",
                    keyName: "pilgrimageBookingViewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "pilgrimage-booking-view-cancel-booking-btn",
                    label: "Cancel Booking",
                    keyName: "pilgrimageBookingViewCancelBookingBtn",
                    class: [["secondary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-view-package-name",
                label: "Package Name",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: "wer",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-view-from-date",
                label: "From Date",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: "eew",
                class: [["col-3"]]
              },
              {
                id: "pilgrimage-booking-view-to-date",
                label: "To Date",
                type: "h4",
                description: "h4 text",
                htmlType: 7,
                value: "ers",
                class: [["col-3"]]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "pilgrimage-booking-view-heading-user-details",
                label: "User details",
                type: "h3",
                description: "h3 text",
                htmlType: 1,
                class: [["col-12"], ["mt-3 mb-3"]]
              },
            ]
          },
        ]
      },
    ]
  },
  {
    id: "hotels-id",
    title: "Hotels",
    link: "",
    subMenu: [
      {
        id: "hotel-management-list-id",
        title: "Hotel Profile Management",
        link: '/hotel-management-list',
        component: "HotelManagementList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "hotel-management-list-main-heading",
                label: "Hotel Profile Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              }
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "hotel-management-list-type",
                label: "Hotel Type",
                type: "radio",
                name: "hotelTypeName",
                description: "radio with label text",
                htmlType: 6,
                value: "",
                class: [["col-8"]],
                keyName: "hotelTypeKey",
                fields: [
                  {
                    id: "hotel-management-list-hotel-gds",
                    label: "Hotel GDS",
                    value: "HOTEL_GDS",
                    htmlFor: "hotel-management-list-hotel-gds-for",
                  },
                  {
                    id: "hotel-management-list-hotel-leisure",
                    label: "Hotel Leisure",
                    value: "HOTEL_LEISURE",
                    htmlFor: "hotel-management-list-hotel-leisure-for",
                  }
                ]
              },
              {
                id: "hotel-management-list-search",
                label: "",
                type: "text",
                keyName: "hotelSearch",
                description: "search with text",
                htmlType: 9,
                value: "",
                placeholder: "search hotel by city name",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "hotelManagementListOpenBtn",
                  edit: "hotelManagementListEditBtn",
                  delete: "hotelManagementListDeleteBtn"
                },
                value: {
                  columns: 8,
                  thead: [
                    "Hotel Name",
                    "City",
                    "Country Code",
                    "State Code"
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "hotel-management-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "hotel-management-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "hotel-management-new-id",
        title: "Hotel Profile Management New",
        link: "/hotel-management-new",
        component: "HotelManagementNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "hotel-management-new-main-heading",
                label: "Add Hotel",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "hotel-management-new-back-btn",
                    label: "Back",
                    keyName: "hotelManagementNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "hotel-management-new-create-btn",
                    label: "Create",
                    keyName: "hotelManagementNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "hotel-management-new-hotel-name",
                keyName: "hotelName",
                label: "Hotel Name",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "hotel-management-new-hotel-name-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-new-location",
                keyName: "hotelLocation",
                label: "Location",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "hotel-management-new-location-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-new-contact",
                keyName: "hotelContact",
                label: "Contact Number",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "hotel-management-new-contact-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-new-email",
                keyName: "hotelEmail",
                label: "Email Id",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "hotel-management-new-email-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-new-star-rating",
                keyName: "hotelStarRating",
                label: "Star Rating",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "hotel-management-new-star-rating-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-new-upload-halal-certificate",
                label: "Upload Halal Certificate",
                type: "file",
                multiple: false,
                keyName: "hotelHalalCertificate",
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "hotel-management-new-upload-halal-certificate-for",
                class: [["col-4"]]
              },
              {
                id: "hotel-management-new-payment-options",
                label: "Payment Options",
                type: "checkbox",
                name: "hotelPaymentOptions",
                description: "checkbox with label text",
                htmlType: 6,
                class: [["col-12"]],
                fields: [
                  {
                    id: "hotel-management-new-paypal",
                    label: "Paypal",
                    value: false,
                    keyName: "hotelManagementPaypal",
                    htmlFor: "hotel-management-new-paypal-for",
                  },
                  {
                    id: "hotel-management-new-bank-transfer",
                    label: "Bank transfer",
                    value: false,
                    keyName: "hotelManagementBankTransfer",
                    htmlFor: "hotel-management-new-bank-transfer-for",
                  },
                  {
                    id: "hotel-management-new-credit-card",
                    label: "Credit card",
                    value: false,
                    keyName: "hotelManagementCreditCard",
                    htmlFor: "hotel-management-new-credit-card-for",
                  },
                  {
                    id: "hotel-management-new-debit-card",
                    label: "Debit card",
                    value: false,
                    keyName: "hotelManagementDebitCard",
                    htmlFor: "hotel-management-new-debit-card-for",
                  }
                ]
              },
              {
                id: "hotel-management-new-blackout-dates",
                keyName: "hotelBlackOutDates",
                label: "BlackOut Dates",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "hotel-management-new-blackout-dates-for",
                placeholder: "Dates with Comma Seperated",
                class: [["col-12"]]
              }
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "hotel-management-new-room-details",
                htmlType: 'group_1',
                class: [["col-6"]],
                fields: [
                  {
                    id: "hotel-management-new-room-details-1",
                    keyName: "hotelManagementNewRoomName_1",
                    label: "Room Details",
                    type: "text",
                    description: "input with label text",
                    value: "",
                    htmlFor: "hotel-management-new-room-name-1-for",
                    placeholder: "Room Name",
                    class: [["mb-2"]]
                  },
                  {
                    id: "hotel-management-new-type-1",
                    keyName: "hotelManagementNewType_1",
                    label: "",
                    type: "text",
                    description: "input with label text",
                    value: "",
                    htmlFor: "hotel-management-new-type-1-for",
                    placeholder: "Type",
                    class: [["mb-2"]]
                  },
                  {
                    id: "hotel-management-new-price-1",
                    keyName: "hotelManagementNewPrice_1",
                    label: "",
                    type: "text",
                    description: "input with label text",
                    value: "",
                    htmlFor: "hotel-management-new-price-1-for",
                    placeholder: "Price",
                    class: [["mb-2"]]
                  },
                  {
                    id: "hotel-management-new-special-price-1",
                    keyName: "hotelManagementNewSpecialPrice_1",
                    label: "",
                    type: "text",
                    description: "input with label text",
                    value: "",
                    htmlFor: "hotel-management-new-special-price-1-for",
                    placeholder: "Special Price For long stay",
                    class: [["mb-2"]]
                  },
                  {
                    id: "hotel-management-new-capacity-1",
                    keyName: "hotelManagementNewcapacity_1",
                    label: "",
                    type: "text",
                    description: "input with label text",
                    value: "",
                    htmlFor: "hotel-management-new-capacity-1-for",
                    placeholder: "Capacity",
                    class: [["mb-2"]]
                  },
                  {
                    id: "hotel-management-new-room-description-1",
                    keyName: "hotelManagementNewRoomDescription_1",
                    label: "",
                    type: "textarea",
                    description: "textarea without label text",
                    value: "",
                    htmlFor: "hotel-management-new-room-description-1-for",
                    placeholder: "Description",
                    class: [["mb-2"]]
                  },
                  {
                    type: "button",
                    actualType: "a",
                    description: "a button",
                    entity: [
                      {
                        id: "hotel-management-new-add-new-room-btn",
                        label: "Add New Room",
                        keyName: "hotelManagementNewAddNewRoomBtn",
                        icon: "bi-plus-circle-fill",
                        class: [["secondary"]]
                      },
                      {
                        id: "hotel-management-new-delete-btn",
                        label: "Delete",
                        keyName: "hotelManagementNewDeleteBtn",
                        icon: "bi-trash",
                        class: [["secondary"]]
                      }
                    ]
                  }
                ]
              },
              {
                id: "hotel-management-new-transportation-guidelines-main",
                htmlType: 'group_1',
                class: [["col-6"]],
                fields: [ 
                  {
                    id: "hotel-management-new-transportation-guidelines",
                    label: "Transportation Guidelines",
                    description: "label text",
                    htmlFor: "hotel-management-new-transportation-guidelines-for",
                    class: [["mb-3", "col-12"]]
                  },                     
                  {
                    id: "hotel-management-new-inclusion",
                    label: "Inclusion",
                    type: "checkbox",
                    name: "hotelInclusions",
                    description: "checkbox with label text",
                    class: [["mb-3", "col-12"]],
                    fields: [
                      {
                        id: "hotel-management-new-hajj-visa",
                        label: "Hajj Visa",
                        value: false,
                        keyName: "hotelManagementHajjVisa",
                        htmlFor: "hotel-management-new-hajj-visa-for",
                      },
                      {
                        id: "hotel-management-new-ticket",
                        label: "Ticket",
                        value: false,
                        keyName: "hotelManagementTicket",
                        htmlFor: "hotel-management-new-ticket-for",
                      },
                    ]
                  },      
                  {
                    id: "hotel-management-new-exclusion",
                    label: "Exclusion",
                    type: "checkbox",
                    name: "hotelExclusions",
                    description: "checkbox with label text",
                    class: [["mt-3", "mb-3", "col-12"]],
                    fields: [
                      {
                        id: "hotel-management-new-excess-bagggage",
                        label: "Excess Bagggage",
                        value: false,
                        keyName: "hotelManagementExcessBagggage",
                        htmlFor: "hotel-management-new-excess-bagggage-for",
                      },
                      {
                        id: "hotel-management-new-tawaf-e-ziyarat",
                        label: "Tawaf-e-Ziyarat",
                        value: false,
                        keyName: "hotelManagementTawafeZiyarat",
                        htmlFor: "hotel-management-new-tawaf-e-ziyarat-for",
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: "hotel-management-view-id",
        title: "Hotel Management View",
        link: '/hotel-management-view',
        component: "HotelManagementView",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "hotel-management-view-main-heading",
                label: "View Hotel Profile Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "hotel-management-view-back-btn",
                    label: "Back",
                    keyName: "hotelManagementViewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "hotel-management-view-cancel-booking-btn",
                    label: "Cancel Booking",
                    keyName: "hotelManagementViewCancelBookingBtn",
                    class: [["secondary", "ms-2"]],
                  }
                ]
              },
            ]
          }
        ]
      },
    ]
  },
  {
    id: "cabs-id",
    title: "Cabs",
    link: "",
    subMenu: [
      {
        id: "cab-promotion-list-id",
        title: "Promotions & Discounts",
        link: '/cab-promotion-list',
        component: "CabPromotionList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "cab-promotion-list-main-heading",
                label: "Promotions",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "cab-promotion-list-new-promotion-btn",
                    label: "Add New",
                    keyName: "cabPromotionListNewPromotionBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "cab-promotion-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "cab-promotion-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "cab-promotion-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "cabPromotionListOpenBtn",
                  edit: "cabPromotionListEditBtn",
                  delete: "cabPromotionListDeleteBtn"
                },
                value: {
                  columns: 5,
                  thead: [
                    "Promo code",
                    "Promo type",
                    "Assign To",
                    "Promo engine",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "cab-promotion-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "cab-promotion-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "cab-promotion-new-id",
        title: "Cab Promotion New",
        link: "/cab-promotion-new",
        component: "CabPromotionNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "cab-promotion-new-main-heading",
                label: "Add Promotion",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "cab-promotion-new-back-btn",
                    label: "Back",
                    keyName: "cabPromotionNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "cab-promotion-new-create-btn",
                    label: "Create",
                    keyName: "cabPromotionNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "cab-promotion-promo-code",
                keyName: "cabPromoCode",
                label: "Promo Code",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "cab-promotion-promo-code-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "cab-promotion-promo-type",
                label: "Promo Type",
                type: "checkbox",
                name: "cabPromoType",
                description: "checkbox with label text",
                htmlType: 6,
                class: [["col-12"]],
                fields: [
                  {
                    id: "cab-promotion-airport-transfers",
                    label: "Airport Transfers",
                    value: false,
                    keyName: "airportTransfers",
                    htmlFor: "cab-promotion-airport-transfers-for",
                  },
                  {
                    id: "cab-promotion-loyalty-reward",
                    label: "Loyalty Reward",
                    value: false,
                    keyName: "loyaltyReward",
                    htmlFor: "cab-promotion-loyalty-reward-for",
                  }
                ]
              },
              {
                id: "cab-promotion-assign-to",
                keyName: "cabAssignTo",
                label: "Assign To",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "cab-promotion-assign-to-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "cab-promotion-promo-engine",
                keyName: "cabPromoEngine",
                label: "Promo Engine",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "cab-promotion-promo-engine-for",
                placeholder: "",
                class: [["col-4"]]
              }
            ]
          }
        ]
      },
      {
        id: "fleet-management-list-id",
        title: "Fleet Management",
        link: '/fleet-management-list',
        component: "FleetManagementList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "fleet-management-list-main-heading",
                label: "Fleet Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "fleet-management-list-new-fleet-btn",
                    label: "Add Fleet",
                    keyName: "fleetManagementNewFleetBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "fleet-management-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "fleet-management-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "fleet-management-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "fleetManagementListOpenBtn",
                  edit: "fleetManagementListEditBtn",
                  delete: "fleetManagementListDeleteBtn"
                },
                value: {
                  columns: 9,
                  thead: [
                    "Vehicle Name",
                    "Driver Name",
                    "Type",
                    "Vehicle Number",
                    "Seating",
                    "Features",
                    "Airport Pricing",
                    "City Tour Pricing",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "fleet-management-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "fleet-management-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "fleet-management-new-id",
        title: "Fleet Management New",
        link: "/fleet-management-new",
        component: "FleetManagementNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "fleet-management-new-main-heading",
                label: "Add Fleet Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "fleet-management-new-back-btn",
                    label: "Back",
                    keyName: "fleetManagementNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "fleet-management-new-create-btn",
                    label: "Create",
                    keyName: "fleetManagementNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "fleet-management-vehicle-name",
                keyName: "cabVehicleName",
                label: "Vehicle Name",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-vehicle-name-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-vehicle-number",
                keyName: "cabVehicleNumber",
                label: "Vehicle Number",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-vehicle-number-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-vehicle-type",
                keyName: "cabVehicleType",
                label: "Vehicle Type",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-vehicle-type-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-vehicle-seating",
                keyName: "cabVehicleSeating",
                label: "Vehicle Seating",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-vehicle-seating-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-vehicle-features",
                keyName: "cabVehicleFeatures",
                label: "Vehicle Features",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-vehicle-features-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-assign-driver",
                keyName: "cabAssignDriver",
                label: "Assign Driver",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-assign-driver-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-airport-pricing",
                keyName: "cabAirportPricing",
                label: "Airport Pricing",
                type: "textarea",
                description: "textarea with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-airport-pricing-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "fleet-management-city-tour-pricing",
                keyName: "cabCityTourPricing",
                label: "City Tour Pricing",
                type: "textarea",
                description: "textarea with label text",
                htmlType: 4,
                value: "",
                htmlFor: "fleet-management-city-tour-pricing-for",
                placeholder: "",
                class: [["col-4"]]
              }
            ]
          }
        ]
      },      
      {
        id: "fleet-management-view-id",
        title: "Fleet Management View",
        link: '/fleet-management-view',
        component: "FleetManagementView",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "fleet-management-view-main-heading",
                label: "View Fleet Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "fleet-management-view-back-btn",
                    label: "Back",
                    keyName: "fleetManagementViewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "fleet-management-view-cancel-booking-btn",
                    label: "Cancel Fleet",
                    keyName: "fleetManagementViewCancelFleetBtn",
                    class: [["secondary", "ms-2"]],
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        id: "driver-management-list-id",
        title: "Driver Management",
        link: '/driver-management-list',
        component: "DriverManagementList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "driver-management-list-main-heading",
                label: "Driver Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "driver-management-list-new-driver-btn",
                    label: "Add Driver",
                    keyName: "driverManagementNewDriverBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "driver-management-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "driver-management-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "driver-management-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "driverManagementListOpenBtn",
                  edit: "driverManagementListEditBtn",
                  delete: "driverManagementListDeleteBtn"
                },
                value: {
                  columns: 8,
                  thead: [
                    "Driver Name",
                    "Mobile Number",
                    "License",
                    "Car Tagged",
                    "Payment Mode",
                    "Star Rating",
                    "Join Date",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "driver-management-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "driver-management-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "driver-management-new-id",
        title: "Driver Management New",
        link: "/driver-management-new",
        component: "DriverManagementNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "driver-management-new-main-heading",
                label: "Add Driver Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "driver-management-new-back-btn",
                    label: "Back",
                    keyName: "driverManagementNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "driver-management-new-create-btn",
                    label: "Create",
                    keyName: "driverManagementNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "driver-management-driver-name",
                keyName: "cabDriverName",
                label: "Driver Name",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "driver-management-driver-name-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "driver-management-mobile-number",
                keyName: "cabMobileNumber",
                label: "Mobile Number",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "driver-management-mobile-number-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "driver-management-license-type",
                keyName: "cabLicense",
                label: "License",
                type: "file",
                multiple: false,
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "driver-management-license-type-for",
                class: [["col-4"]]
              },
              {
                id: "driver-management-tag-car",
                keyName: "cabTagCar",
                label: "Tag Car",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "driver-management-tag-car-for",
                placeholder: "",
                class: [["col-4"]]
              },              
              {
                id: "driver-management-payment",
                keyName: "cabPayment",
                label: "Payment",
                type: "singleSelect",
                size: 300,
                description: "select with label checkbox",
                htmlType: 4,
                data: ['Weekly', 'Monthly'].map(
                  item => ({ label: item, value: item })
                ),
                value: '',
                htmlFor: "driver-management-payment-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "driver-management-rating",
                keyName: "cabRating",
                label: "Star Rating",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "driver-management-rating-for",
                placeholder: "",
                class: [["col-4"]]
              },              
              {
                id: "driver-management-join-date",
                keyName: "cabJoinDate",
                label: "Join Date",
                type: "datepicker",
                description: "datepicker rsuite",
                htmlType: 4,
                oneTap: true,
                htmlFor: "driver-management-airport-pricing-for",
                size: "lg",
                value: null,
                dateStyles,
                placeholder: "Select Date",
                format: "dd-MM-yyyy",
                class: [["col-3"]]
              }
            ]
          }
        ]
      },
      {
        id: "driver-management-view-id",
        title: "Driver Management View",
        link: '/driver-management-view',
        component: "DriverManagementView",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "driver-management-view-main-heading",
                label: "View Driver Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "driver-management-view-back-btn",
                    label: "Back",
                    keyName: "driverManagementViewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "driver-management-view-cancel-driver-btn",
                    label: "Cancel Driver",
                    keyName: "driverManagementViewCancelDriverBtn",
                    class: [["secondary", "ms-2"]],
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        id: "cab-booking-list-id",
        title: "Ride Booking & Tracking",
        link: '/cab-booking-list',
        component: "CabBookingList",
        modal: {
          id: "cab-booking-list-reply-modal",
          heading: "Reply",
          btn1: "Close",
          btn2: "Submit",
          modalKey: "reviewModal",
          keyName: "cabBookTextArea",
          type: "textarea",
          value: "",
          key: "cabBookingListReviewBtn",
          description: "Respond to review",
          htmlType: "modal_obj",
          placeholder: "",
          class: [["col-auto", "me-auto"], [""]]
        },
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "cab-booking-list-main-heading",
                label: "Bookings",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              }
            ]
          },
          {
            class: ["mb-4"],
            fields: [
              {
                id: "cab-booking-list-cards",
                label: "",
                type: "card",                
                value: [
                  { label: "Total Rides", amount: "$10,023.00" },
                  { label: "Earnings", amount: "$823.00" },
                  { label: "Tips", amount: "$10,023.00" },
                ],
                description: "card with text",
                htmlType: 13
              }
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "cab-booking-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "cab-booking-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "cab-booking-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  fixId: "fixId",
                  modalKey: "reviewModal",
                },
                value: {
                  columns: 11,
                  thead: [
                    "Vehicle Name",
                    "Vehicle Number",
                    "Pickup Location",
                    "Pickup Date",
                    "Drop off Location",
                    "Drop off Date",
                    "Ride Status",
                    "Payment Status",
                    "Star Rating",
                    "Review",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "cab-booking-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "cab-booking-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      }
    ]
  },
  {
    id: "restaurants-id",
    title: "Restaurants",
    link: "",
    subMenu: [
      {
        id: "restaurant-menu-list-id",
        title: "Menu Management",
        link: '/restaurant-menu-list',
        component: "RestaurantMenuList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "restaurant-menu-list-main-heading",
                label: "Menu Management",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "restaurant-menu-list-new-menu-btn",
                    label: "Add New",
                    keyName: "restaurantMenuListNewMenuBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "restaurant-menu-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "restaurant-menu-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "restaurant-menu-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "restaurantMenuListOpenBtn",
                  edit: "restaurantMenuListEditBtn",
                  delete: "restaurantMenuListDeleteBtn"
                },
                value: {
                  columns: 7,
                  thead: [
                    "Menu Item",
                    "Type",
                    "Photos",
                    "Halal Certification",
                    "Description",
                    "Availability",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "restaurant-menu-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "restaurant-menu-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "restaurant-menu-new-id",
        title: "Menu Add",
        link: "/restaurant-menu-new",
        component: "RestaurantMenuNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "restaurant-menu-new-main-heading",
                label: "Menu Add",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "restaurant-menu-new-back-btn",
                    label: "Back",
                    keyName: "restaurantMenuNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "restaurant-menu-new-create-btn",
                    label: "Create",
                    keyName: "restaurantMenuNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "restaurant-menu-item-name",
                keyName: "restaurantItemName",
                label: "Item Name",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "restaurant-menu-item-name-for",
                placeholder: "",
                class: [["col-4"]]
              },                            
              {
                id: "restaurant-menu-type",
                keyName: "restaurantMenuType",
                label: "Type",
                type: "singleSelect",
                size: 300,
                description: "select with single dropdown",
                htmlType: 4,
                data: ['Veg', 'Non-Veg'].map(
                  item => ({ label: item, value: item })
                ),
                value: '',
                htmlFor: "restaurant-menu-type-for",
                placeholder: "",
                class: [["col-4"]]
              },              
              {
                id: "restaurant-menu-photo",
                keyName: "restaurantMenuPhoto",
                label: "Photo",
                type: "file",
                multiple: false,
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "restaurant-menu-photo-for",
                class: [["col-4"]]
              },           
              {
                id: "restaurant-menu-halal-certification",
                keyName: "restaurantMenuHalalCertification",
                label: "Halal Certification",
                type: "file",
                multiple: false,
                description: "input with label text",
                htmlType: 4,
                value: null,
                htmlFor: "restaurant-menu-halal-certification-for",
                class: [["col-4"]]
              },
              {
                id: "restaurant-menu-description",
                keyName: "restaurantMenuDescription",
                label: "Description",
                type: "textarea",
                description: "textarea with label text",
                htmlType: 4,
                value: "",
                htmlFor: "restaurant-menu-description-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "restaurant-menu-availability",
                label: "Availability",
                type: "checkbox",
                name: "restaurantMenuAvailability",
                description: "checkbox with label text",
                htmlType: 6,
                class: [["col-12"]],
                fields: [
                  {
                    id: "restaurant-menu-dine-in",
                    label: "Dine-In",
                    value: false,
                    keyName: "restaurantMenuDineIn",
                    htmlFor: "restaurant-menu-dine-in-for",
                  },
                  {
                    id: "restaurant-menu-takeout",
                    label: "Takeout",
                    value: false,
                    keyName: "restaurantMenuTakeout",
                    htmlFor: "restaurant-menu-takeout-for",
                  },
                  {
                    id: "restaurant-menu-delivery",
                    label: "Delivery",
                    value: false,
                    keyName: "restaurantMenuDelivery",
                    htmlFor: "restaurant-menu-delivery-for",
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        id: "restaurant-promotion-list-id",
        title: "Promotions & Discounts",
        link: '/restaurant-promotion-list',
        component: "RestaurantPromotionList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "restaurant-promotion-list-main-heading",
                label: "Promotions",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                class: [["col-auto"], [""]],
                entity: [
                  {
                    id: "restaurant-promotion-list-new-promotion-btn",
                    label: "Add New",
                    keyName: "restaurantPromotionListNewPromotionBtn",
                    class: [["primary", ""]]
                  }
                ]
              },
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "restaurant-promotion-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "restaurant-promotion-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "restaurant-promotion-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                keyName: {
                  open: "restaurantPromotionListOpenBtn",
                  edit: "restaurantPromotionListEditBtn",
                  delete: "restaurantPromotionListDeleteBtn"
                },
                value: {
                  columns: 5,
                  thead: [
                    "Promo code",
                    "Promo type",
                    "Assign To",
                    "Promo engine",
                    "Actions",
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "restaurant-promotion-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "restaurant-promotion-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
      {
        id: "restaurant-promotion-new-id",
        title: "Restaurant Promotion New",
        link: "/restaurant-promotion-new",
        component: "RestaurantPromotionNew",
        hide: "hider",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "restaurant-promotion-new-main-heading",
                label: "Add Promotion",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              },
              {
                type: "button",
                actualType: "a",
                description: "a button",
                htmlType: 2,
                entity: [
                  {
                    id: "restaurant-promotion-new-back-btn",
                    label: "Back",
                    keyName: "restaurantPromotionNewBackBtn",
                    class: [["secondary", ""]]
                  },
                  {
                    id: "restaurant-promotion-new-create-btn",
                    label: "Create",
                    keyName: "restaurantPromotionNewCreateBtn",
                    class: [["primary", "ms-2"]],
                  }
                ]
              },
            ]
          },
          {
            class: [""],
            fields: [
              {
                id: "restaurant-promotion-promo-code",
                keyName: "restaurantPromoCode",
                label: "Promo Code",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "restaurant-promotion-promo-code-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "restaurant-promotion-promo-type",
                label: "Promo Type",
                type: "checkbox",
                name: "restaurantPromoType",
                description: "checkbox with label text",
                htmlType: 6,
                class: [["col-12"]],
                fields: [
                  {
                    id: "restaurant-promotion-dine-in",
                    label: "Dine In",
                    value: false,
                    keyName: "restaurantDineIn",
                    htmlFor: "restaurant-promotion-dine-in-for",
                  },
                  {
                    id: "restaurant-promotion-dine-out",
                    label: "Dine Out",
                    value: false,
                    keyName: "restaurantDineOut",
                    htmlFor: "restaurant-promotion-dine-out-for",
                  },
                  {
                    id: "restaurant-promotion-delivery",
                    label: "Delivery",
                    value: false,
                    keyName: "restaurantDelivery",
                    htmlFor: "restaurant-promotion-delivery-for",
                  }
                ]
              },
              {
                id: "restaurant-promotion-assign-to",
                keyName: "restaurantAssignTo",
                label: "Assign To",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "restaurant-promotion-assign-to-for",
                placeholder: "",
                class: [["col-4"]]
              },
              {
                id: "restaurant-promotion-promo-engine",
                keyName: "restaurantPromoEngine",
                label: "Promo Engine",
                type: "text",
                description: "input with label text",
                htmlType: 4,
                value: "",
                htmlFor: "restaurant-promotion-promo-engine-for",
                placeholder: "",
                class: [["col-4"]]
              }
            ]
          }
        ]
      },
      {
        id: "restaurant-payment-list-id",
        title: "Payments & Payouts",
        link: '/restaurant-payment-list',
        component: "RestaurantPaymentList",
        hide: "",
        content: [
          {
            class: ["mb-4"],
            fields: [
              {
                id: "restaurant-payment-list-main-heading",
                label: "Restaurant Payments",
                type: "h2",
                description: "h2 text",
                htmlType: 1,
                class: [["col-auto", "me-auto"], [""]]
              }
            ]
          },
          {
            class: ["mb-4"],
            fields: [
              {
                id: "restaurant-payment-list-cards",
                label: "",
                type: "card",
                value: [
                  { label: "Total Dine-in", amount: "$10,023.00" },
                  { label: "Total Takeaway", amount: "$823.00" },
                  { label: "Total Delivery", amount: "$10,023.00" },
                ],
                description: "card with text",
                htmlType: 13
              }
            ]
          },
          {
            class: ["mt-4"],
            fields: [
              {
                id: "restaurant-payment-list-dropdown-option",
                label: "",
                type: "dropdown",
                description: "dropdown with option",
                htmlType: 8,
                value: "",
                class: [["col-9", "text-end"]]
              },
              {
                id: "restaurant-payment-list-search",
                label: "",
                type: "search",
                description: "search with text",
                htmlType: 9,
                value: "",
                class: [["col-3"]]
              },
              {
                id: "restaurant-payment-list-table",
                label: "",
                type: "table",
                description: "table with thead tbody",
                htmlType: 10,
                value: {
                  columns: 5,
                  thead: [
                    "Date & Time",
                    "Customer Name",
                    "Order Details",
                    "Order Amount",
                    "Type"
                  ],
                  tbody: 'loading'
                },
                class: [["col-12"], ["table-responsive", "mt-3"]]
              },
              {
                id: "restaurant-payment-list-paginator",
                label: "",
                type: "paginator",
                description: "paginator text",
                htmlType: 11,
                value: "",
                class: [["col-6"]]
              },
              {
                id: "restaurant-payment-list-pagination",
                label: "",
                type: "pagination",
                description: "pagination",
                htmlType: 12,
                value: "",
                class: [["col-6"]]
              }
            ]
          },
        ]
      },
    ]
  }
];